import Logo from "../images/logo-ajuda.png";
import { States } from "../services/news";

const Menu = ({ ufActive, setActive, setUfActive }: any) => {
  const handleState = (code: string) => {
    setUfActive(code);
  };

  return (
    <>
      <header className="header">
        <img src={Logo} alt="logo-ajuda-concursos" className="logo" />
        <h1 className="title">O melhor lugar para buscar os concursos que deseja</h1>
      </header>
      <nav className="menu-state">
        <ul>
          {States.map((state) => (
            <li
              key={state.code}
              className={ufActive === state.code ? "active" : "inactive"}
              onClick={() => handleState(state.code)}
            >
              {state.code}
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Menu;
