import { useEffect, useState } from "react";
import NewsItem from "./NewsItem";
import { NewsData } from "../services/news";
import News from "./News";
import { ClipLoader } from "react-spinners";

type NewsGridProps = {
  newsList: NewsData[];
  isLoading: boolean;
};

const NewsGrid = ({ newsList, isLoading }: NewsGridProps) => {
  const [newsData, setNews] = useState<NewsData | null>(null);

  useEffect(() => {
    setNews(null);
  }, [newsList]);

  if (isLoading) {
    return (
      <div className="news-page">
        <h2 style={{ color: "#28696a", marginTop: "5em", marginBottom: "1em" }}>
          BUSCABDO AS NOTÍCIAS!!!
        </h2>
        <ClipLoader
          color="#28696a"
          loading={isLoading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  if (newsList.length === 0) {
    return (
      <div className="news-page">
        <h2 style={{ color: "#28696a", marginTop: "5em", marginBottom: "1em" }}>
          NÃO FORAM ENCONTRADAS NOTÍCIAS PARA O FILTRO UTILIZADO!!
        </h2>
      </div>
    );
  }

  return (
    <div className="news-page">
      {newsData ? <News news={newsData} /> : ""}
      <div className="news-grid">
        {newsList.map((news: any, i: number) => (
          <NewsItem key={i} news={news} setNews={setNews} />
        ))}
      </div>
    </div>
  );
};

export default NewsGrid;
