import { format } from "date-fns";
import NewsImg from "../images/news.jpg";
import { NewsData, States } from "../services/news";
import { useEffect, useState } from "react";

type NewsItemProps = {
  news: NewsData;
  setNews: (news: NewsData | null) => void;
};

const NewsItem = ({ news, setNews }: NewsItemProps) => {
  const [image, setImage] = useState<string | null>(null);

  const getState = (uf: string) => {
    return States.find((s) => s.code === uf)?.name;
  };

  useEffect(() => {
    if (news) {
      const img = news.files.find(
        (f) => f.extension === "png" || f.extension === "jpg" || f.extension === "jpeg"
      );

      if (img && img.path) {
        setImage(img.path);
      } else {
        setImage(null);
      }
    }
  }, [news]);

  return (
    <div onClick={() => setNews(news)} className="article">
      <div className="article-image">
        <img src={image || NewsImg} alt={news.title} />
      </div>
      <div className="article-content">
        <div className="article-source">
          <span>{getState(news.state)}</span>
          {news.committee && (
            <span>
              {" "}
              {news.state && <>-</>} {news.committee.name}
            </span>
          )}
        </div>
        <div className="article-title">
          <h3>{news.title}</h3>
        </div>
        <p className="article-description">{`${news.content
          .substring(0, 120)
          .replace(/<\/?[^>]+(>|$)/g, "")}...`}</p>
        <div className="article-details">
          <small>
            <b>Última atualização: </b>
            {format(news.date, "dd/MM/yyyy HH:mm:ss")}
          </small>
        </div>
      </div>
    </div>
  );
};

export default NewsItem;
