import { useEffect, useState } from "react";
import Menu from "./components/Menu";
import NewsGrid from "./components/NewsGrid";

import { NewsData, findAll } from "./services/news";
import AdsenseAd from "./components/AdsenseAd";

function App() {
  const [active, setActive] = useState(1);
  const [ufActive, setUfActive] = useState("Todos");
  const [newsList, setNewsList] = useState<NewsData[]>([]);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    setIsloading(true);
    const listAll = async () => {
      const data = await findAll(ufActive, null);
      setNewsList(data);
      setIsloading(false);
    };

    listAll();
  }, [ufActive]);

  return (
    <div className="App">
      <AdsenseAd />
      <Menu active={active} ufActive={ufActive} setActive={setActive} setUfActive={setUfActive} />
      <NewsGrid newsList={newsList} isLoading={isLoading} />
      <AdsenseAd />
    </div>
  );
}

export default App;
