import React, { useEffect, useState } from "react";
import { NewsData, States } from "../services/news";
import NewsImg from "../images/news.jpg";
import { format } from "date-fns";

type NewsProps = {
  news: NewsData | null;
};

export function createMarkup(dirty: string) {
  return { __html: dirty };
}

const News = ({ news }: NewsProps) => {
  const [image, setImage] = useState<string | null>(null);
  const getState = (uf: string) => {
    return States.find((s) => s.code === uf)?.name;
  };

  useEffect(() => {
    if (news) {
      const img = news.files.find(
        (f) => f.extension === "png" || f.extension === "jpg" || f.extension === "jpeg"
      );

      if (img && img.path) {
        setImage(img.path);
      } else {
        setImage(null);
      }
      window.scrollTo(0, 0);
    }
  }, [news]);

  return (
    <div className="news">
      {news ? (
        <div>
          <div className="article-image">
            <img src={image || NewsImg} alt={news.title} />
          </div>
          <div className="article-content">
            <div className="article-source">
              <span>{getState(news.state)}</span>
              {news.committee && (
                <span>
                  {" "}
                  {news.state && <>-</>} {news.committee.name}
                </span>
              )}
              <small>
                <b>Última atualização: </b>
                {format(news.date, "dd/MM/yyyy HH:mm:ss")}
              </small>
            </div>
            <div className="news-title">{news.title}</div>

            <div
              className="news-content"
              dangerouslySetInnerHTML={createMarkup(news.content)}
            ></div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default News;
